import { lazy, Suspense } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { useMount } from "react-use";

import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";
import { useLocalization } from "@app/features/localization/localization";
import { USER_ROLES_PERMISSIONS } from "@app/features/permissions/constants/permissions.scopes";
import { setPermissions } from "@app/features/permissions/permissions";
import { getCookieTokens } from "@app/helpers/auth.helpers";
import { useAppDispatch, useAppSelector } from "@app/store/store";
import { setAuthenticated } from "@app/store/token.slice";
import { UserRoleEnum } from "@app/constants/common";
import Hotjar from "@hotjar/browser";

const Routes = lazy(() => import("../../routes/Routes"));

const App = () => {
  const { loadingTranslation } = useLocalization({ shouldCall: true });
  const { accessToken } = getCookieTokens();
  const dispatch = useAppDispatch();

  const { loading: loadingUser } = useAppSelector(state => state.auth);

  useMount(async () => {
    const siteId = process.env.REACT_APP_HOTJAR_SPACE_ID;
    const hotjarVersion = 6;
    if (siteId) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Hotjar.init(siteId, hotjarVersion);
    }

    if (accessToken) {
      // TODO #15: remove comments when /users/me will be added
      // dispatch(getUser());

      // TODO #15: remove this 2 lines when /users/me will be added
      dispatch(setAuthenticated());
      dispatch(setPermissions(USER_ROLES_PERMISSIONS[UserRoleEnum.FULL]));
    } else {
      dispatch(setPermissions(USER_ROLES_PERMISSIONS[UserRoleEnum.GUEST]));
    }
  });

  const loading = (
    <LoadingSpinner isFullscreen text="Loading Apigale Web App" />
  );

  if (loadingTranslation || loadingUser) {
    return loading;
  }

  return (
    <Suspense fallback={loading}>
      <Router>
        <Routes />
      </Router>
    </Suspense>
  );
};

export default App;
