import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";
import {useEffect, useState} from "react";

import {logger} from "@app/utils/logger";
import {AxiosResponse} from "axios";
import {DeserializeData} from "@app/utils/common";
import {ITool, Tools} from "@app/types/common.types";

import EmptyState from "@app/components/EmptyState";
import {Operations} from "@app/types/api.types";
import ApiOperation from "@app/components/ApiOperation/ApiOperation";
import {ApiTools} from "src/components/ApiTools";
import {TOOLS_CATEGORY_ORDER} from "@app/constants/common";
import {
  serviceSpecificationsAPIOperationsApi,
  serviceSpecificationsAPIToolsApi,
} from "@app/features/services-specifications/api/serviceSpecifications.api";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ServiceSpecificationOperations = (props: any) => {
  const [tools, setTools] = useState<Tools<ITool> | null>(null);
  const [operations, setOperations] = useState<Operations | null>(null);

  const { id } = props.data;
  const [isLoading, setLoadingStatus] = useState<boolean>(true);

  const getOperations = async () => {
    try {
      setLoadingStatus(true);
      const { data }: AxiosResponse =
        await serviceSpecificationsAPIOperationsApi.serviceSpecificationsServiceSpecificationIdApiOperationsGet(
          {
            serviceSpecificationId: id,
          }
        );

      DeserializeData(data).then(res => {
        setOperations(res);
      });

      setOperations(operations);
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const getTools = async () => {
    try {
      setLoadingStatus(true);
      const { data }: AxiosResponse =
        await serviceSpecificationsAPIToolsApi.serviceSpecificationsServiceSpecificationIdApiToolsGet(
          {
            serviceSpecificationId: id,
          }
        );

      DeserializeData(data).then(res => {
        const tools: Tools<ITool> = {};

        res.map((tool: ITool) => {
          tools[tool.category] = tools[tool.category]
            ? [...tools[tool.category], tool]
            : [tool];
        });
        setTools(tools);
      });
      // const links = deserializeOperationsLinks(data.data);
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    getTools();
    getOperations();
  }, [id]);

  return (
    <div id="wrap-api-operations">
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <div>
          <div className="">
            {(!operations || (operations && operations.length === 0)) && (
              <EmptyState title="" text="No API Operations yet" />
            )}

            {operations && (
              <ApiOperation operations={operations}>
                {tools && Object.keys(tools).length > 0 ? (
                  TOOLS_CATEGORY_ORDER.map(group => (
                    <ApiTools tools={tools} group={group} />
                  ))
                ) : (
                    <EmptyState title=""
                                text="No API tools have been provided. Please navigate to the 'API Tools' menu on the left to add them."/>
                )}
              </ApiOperation>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceSpecificationOperations;
